import { useState, useEffect, useCallback } from 'react';
import { usePGlite } from '@electric-sql/pglite-react';

interface Message {
  id: number;
  sender: string;
  content: string;
  timestamp: Date;
}

interface Cursor {
  timestamp: Date;
  id: number;
}

interface UserInfo {
  name: string;
}

const PAGE_SIZE = 20;

export function useMessageList(conversationId: string) {
  const db = usePGlite();
  const [messages, setMessages] = useState<Message[]>([]);
  const [userName, setUserName] = useState<string>('');
  const [isLoadingNewer, setIsLoadingNewer] = useState<boolean>(false);
  const [isLoadingOlder, setIsLoadingOlder] = useState<boolean>(false);
  const [hasMoreNewer, setHasMoreNewer] = useState<boolean>(true);
  const [hasMoreOlder, setHasMoreOlder] = useState<boolean>(true);
  const [newerCursor, setNewerCursor] = useState<Cursor | null>(null);
  const [olderCursor, setOlderCursor] = useState<Cursor | null>(null);

  const fetchMessages = useCallback(async (cursor: Cursor | null, direction: 'newer' | 'older'): Promise<{ messages: Message[], hasMore: boolean }> => {
    let query = `
      SELECT id, sender, content, timestamp
      FROM messages
      WHERE conversation_id = $1
    `;
    const params: (string | number | Date)[] = [conversationId];

    if (cursor) {
      if (direction === 'newer') {
        query += ` AND (timestamp, id) > ($2, $3)`;
        params.push(cursor.timestamp, cursor.id);
        query += ` ORDER BY timestamp ASC, id ASC`;
      } else {
        query += ` AND (timestamp, id) < ($2, $3)`;
        params.push(cursor.timestamp, cursor.id);
        query += ` ORDER BY timestamp DESC, id DESC`;
      }
    } else {
      query += ` ORDER BY timestamp DESC, id DESC`;
    }

    query += ` LIMIT ${PAGE_SIZE + 1}`;

    const result = await db.query(query, params);
    let newMessages = result.rows as Message[];

    const hasMore = newMessages.length > PAGE_SIZE;
    if (hasMore) {
      newMessages = newMessages.slice(0, PAGE_SIZE);
    }

    if (direction === 'older') {
      newMessages.reverse();
    }

    return { messages: newMessages, hasMore };
  }, [db, conversationId]);

  const fetchUserName = useCallback(async (): Promise<string | null> => {
    const userResult = await db.query<UserInfo>('SELECT name FROM user_info WHERE id = 1');
    return userResult.rows.length > 0 ? userResult.rows[0].name : null;
  }, [db]);

  const fetchMessageById = useCallback(async (messageId: number): Promise<Message | null> => {
    const query = `
      SELECT id, sender, content, timestamp
      FROM messages
      WHERE conversation_id = $1 AND id = $2
    `;
    const result = await db.query<Message>(query, [conversationId, messageId]);
    return result.rows.length > 0 ? result.rows[0] : null;
  }, [db, conversationId]);

  const loadNewer = useCallback(async () => {
    if (isLoadingNewer || !hasMoreNewer) return;
    setIsLoadingNewer(true);
    try {
      const { messages: newMessages, hasMore } = await fetchMessages(newerCursor, 'newer');
      setMessages(prevMessages => [...prevMessages, ...newMessages]);
      setHasMoreNewer(hasMore);
      if (newMessages.length > 0) {
        const lastMessage = newMessages[newMessages.length - 1];
        setNewerCursor({ timestamp: lastMessage.timestamp, id: lastMessage.id });
      }
    } catch (error) {
      console.error('Error loading newer messages:', error);
    } finally {
      setIsLoadingNewer(false);
    }
  }, [fetchMessages, newerCursor, isLoadingNewer, hasMoreNewer]);

  const loadOlder = useCallback(async () => {
    if (isLoadingOlder || !hasMoreOlder) return;
    setIsLoadingOlder(true);
    try {
      const { messages: newMessages, hasMore } = await fetchMessages(olderCursor, 'older');
      setMessages(prevMessages => [...newMessages, ...prevMessages]);
      setHasMoreOlder(hasMore);
      if (newMessages.length > 0) {
        const firstMessage = newMessages[0];
        setOlderCursor({ timestamp: firstMessage.timestamp, id: firstMessage.id });
      }
    } catch (error) {
      console.error('Error loading older messages:', error);
    } finally {
      setIsLoadingOlder(false);
    }
  }, [fetchMessages, olderCursor, isLoadingOlder, hasMoreOlder]);

  const loadMessagesAroundId = useCallback(async (messageId: number) => {
    const targetMessage = await fetchMessageById(messageId);
    if (!targetMessage) {
      console.error('Message not found');
      return;
    }

    const olderCursor: Cursor = { timestamp: targetMessage.timestamp, id: targetMessage.id };
    const newerCursor: Cursor = { timestamp: targetMessage.timestamp, id: targetMessage.id };

    setIsLoadingOlder(true);
    setIsLoadingNewer(true);

    try {
      const [olderResult, newerResult] = await Promise.all([
        fetchMessages(olderCursor, 'older'),
        fetchMessages(newerCursor, 'newer')
      ]);

      const combinedMessages = [...olderResult.messages, targetMessage, ...newerResult.messages];
      setMessages(combinedMessages);
      setHasMoreOlder(olderResult.hasMore);
      setHasMoreNewer(newerResult.hasMore);

      if (olderResult.messages.length > 0) {
        const firstMessage = olderResult.messages[0];
        setOlderCursor({ timestamp: firstMessage.timestamp, id: firstMessage.id });
      }

      if (newerResult.messages.length > 0) {
        const lastMessage = newerResult.messages[newerResult.messages.length - 1];
        setNewerCursor({ timestamp: lastMessage.timestamp, id: lastMessage.id });
      }
    } catch (error) {
      console.error('Error loading messages around ID:', error);
    } finally {
      setIsLoadingOlder(false);
      setIsLoadingNewer(false);
    }
  }, [fetchMessages, fetchMessageById]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const name = await fetchUserName();
      if (name) setUserName(name);

      const { messages: initialMessages, hasMore } = await fetchMessages(null, 'older');
      setMessages(initialMessages);
      setHasMoreOlder(hasMore);
      setHasMoreNewer(false);

      if (initialMessages.length > 0) {
        const firstMessage = initialMessages[0];
        const lastMessage = initialMessages[initialMessages.length - 1];
        setNewerCursor({ timestamp: lastMessage.timestamp, id: lastMessage.id });
        setOlderCursor({ timestamp: firstMessage.timestamp, id: firstMessage.id });
      }
    };

    fetchInitialData();
  }, [fetchMessages, fetchUserName]);

  return {
    messages,
    userName,
    isLoadingNewer,
    isLoadingOlder,
    hasMoreNewer,
    hasMoreOlder,
    loadNewer,
    loadOlder,
    loadMessagesAroundId
  };
}

