import React, { useState, useRef, useCallback } from 'react';
import { Unzip, AsyncUnzipInflate } from 'fflate';
import { usePGlite } from "@electric-sql/pglite-react";

interface FileUploadProps {
  onDataImported: () => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onDataImported }) => {
  const db = usePGlite()
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [totalMessages, setTotalMessages] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const processedSizeRef = useRef<number>(0);
  const totalSizeRef = useRef<number>(0);

  const updateProgress = useCallback(() => {
    const percentComplete = Math.round((processedSizeRef.current / totalSizeRef.current) * 100);
    setProgress(percentComplete);
  }, []);

  async function digestMessage(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsProcessing(true);
      setProgress(0);
      setTotalMessages(0);
      processedSizeRef.current = 0;
      totalSizeRef.current = file.size;

      try {
        const unzip = new Unzip();
        const participantCounts: { [key: string]: number } = {};
        let messageCount = 0;

        unzip.register(AsyncUnzipInflate);

        unzip.onfile = async (file) => {
          const validFolders = ['inbox', 'e2ee_cutover', 'filtered_threads'];
          const isValidFile = validFolders.some(folder => 
            file.name.startsWith(`your_facebook_activity/messages/${folder}/`) && file.name.endsWith('.json')
          );

          if (isValidFile) {
            let fileContent = '';
            file.ondata = async (err, data, final) => {
              if (err) {
                console.error(`Error processing ${file.name}:`, err);
                return;
              }

              fileContent += new TextDecoder().decode(data);

              if (final) {
                try {
                  const jsonData = JSON.parse(fileContent, jsonReviver);

                  jsonData.participants.forEach((participant: { name: string }) => {
                    participantCounts[participant.name] = (participantCounts[participant.name] || 0) + 1;
                  });

                  const conversationId = jsonData.thread_path.split('/').pop();
                  const conversationTitle = jsonData.title || `Conversation ${conversationId}`;
                  await db.query(`
                    INSERT INTO conversations (id, title)
                    VALUES ($1, $2)
                    ON CONFLICT (id) DO UPDATE SET title = $2
                    `, [conversationId, conversationTitle]);

                  for (const message of jsonData.messages) {
                    const messageHash = await digestMessage(
                      `${message.sender_name}|${message.timestamp_ms}|${message.content}`
                    );

                    try {
                      await db.query(`
                        INSERT INTO messages (conversation_id, sender, timestamp, content, message_hash)
                        VALUES ($1, $2, $3, $4, $5)
                        ON CONFLICT (message_hash) DO NOTHING
                        `, [
                          conversationId,
                          message.sender_name,
                          new Date(message.timestamp_ms),
                          message.content,
                          messageHash
                        ]);
                      messageCount++;
                    } catch (error) {
                      if (!(error instanceof Error) || !error.message.includes('UNIQUE constraint failed')) {
                        throw error;
                      }
                    }
                  }
                }
                catch (e) {
                  console.error(`Error parsing ${file.name}`);
                }

                setTotalMessages(messageCount);
              }
            };

            file.start();
          }
        };

        const fileStream = await file.stream();
        const reader = fileStream.getReader();

        let chunkCount = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          unzip.push(value);
          
          processedSizeRef.current += value.length;
          chunkCount++;
          
          if (chunkCount % 20 === 0) {
            updateProgress();
            // Force a repaint
            await new Promise(resolve => requestAnimationFrame(resolve));
          }
        }

        const userNameArray = Object.entries(participantCounts).sort((a, b) => b[1] - a[1]);
        const userName = userNameArray[0][0];

        await db.query(`
          INSERT INTO user_info (id, name) 
          VALUES (1, $1) 
          ON CONFLICT (id) DO UPDATE SET name = $1
          `, [userName]);

        onDataImported();
      } catch (error) {
        console.error('Error processing file:', error);
        setErrorMessage(`Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
      } finally {
        setIsProcessing(false);
        updateProgress(); // Ensure final progress is shown
      }
    }
  };

  const decodeUtf8 = (s: string) => {
    return decodeURIComponent(escape(s));
  };

  const jsonReviver = (key: string, value: any) => {
    if (typeof value === 'string') {
      return decodeUtf8(value);
    }
    return value;
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="file-upload w-full max-w-sm mx-auto px-4">
      <input 
        type="file" 
        ref={fileInputRef}
        onChange={handleFileUpload} 
        accept=".zip" 
        className="hidden"
      />
      <button 
        onClick={handleButtonClick}
        className="btn btn-primary w-full"
        disabled={isProcessing}
      >
        {isProcessing ? 'Processing...' : 'Select Facebook Data ZIP'}
      </button>
      {isProcessing && (
        <div className="mt-4">
          <progress className="progress progress-primary w-full" value={progress} max="100"></progress>
          <p className="mt-1 text-sm">{progress}% processed</p>
        </div>
      )}
      {errorMessage && <p className="mt-2 text-sm break-all">{errorMessage}</p>}
      {totalMessages > 0 && <p className="mt-2 text-sm font-semibold text-success">{totalMessages} messages imported</p>}
    </div>
  );
};

export default FileUpload;

