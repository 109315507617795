import React, { useRef, useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { usePGlite } from '@electric-sql/pglite-react';
import { useMessageList } from '../hooks/useMessageList';

const MessageList: React.FC = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const db = usePGlite();
  const messageListRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef<number>(0);
  const prevScrollHeightRef = useRef<number>(0);
  const isFirstRenderRef = useRef<boolean>(true);
  const {
    messages,
    userName,
    isLoadingNewer,
    isLoadingOlder,
    hasMoreNewer,
    hasMoreOlder,
    loadNewer,
    loadOlder,
    loadMessagesAroundId
  } = useMessageList(conversationId || '');

  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null);
  const [title, setTitle] = useState<string>('');
  const [isFromSearch, setIsFromSearch] = useState(false);

  const fetchConversationTitle = useCallback(async (conversationId: string): Promise<string> => {
    try {
      const result = await db.query('SELECT title FROM conversations WHERE id = $1', [conversationId]);
      return result.rows[0]?.title || 'Unknown Conversation';
    } catch (error) {
      console.error('Error fetching conversation title:', error);
      return 'Unknown Conversation';
    }
  }, [db]);

  useEffect(() => {
    if (conversationId) {
      const fetchTitle = async () => {
        const fetchedTitle = await fetchConversationTitle(conversationId || '');
        setTitle(fetchedTitle);
      };
      fetchTitle();
    }
  }, [conversationId, fetchConversationTitle]);

  useEffect(() => {
    if (location.state?.selectedMessageId) {
      setSelectedMessageId(location.state.selectedMessageId);
      setIsFromSearch(true);
      loadMessagesAroundId(location.state.selectedMessageId);
    } else {
      setIsFromSearch(false);
    }
  }, [location.state, loadMessagesAroundId]);

  useLayoutEffect(() => {
    if (messageListRef.current) {
      if (isFirstRenderRef.current && messages.length > 0) {
        // Scroll to bottom on initial load
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        isFirstRenderRef.current = false;
      } else if (selectedMessageId && isFromSearch) {
        // Scroll to selected message
        const element = document.getElementById(`message-${selectedMessageId}`);
        if (element) {
          element.scrollIntoView({ block: 'center' });
        }
        setIsFromSearch(false);
      } else if (prevScrollHeightRef.current > 0) {
        // Maintain relative scroll position when loading older messages
        const scrollDifference = messageListRef.current.scrollHeight - prevScrollHeightRef.current;
        messageListRef.current.scrollTop = scrollPositionRef.current + scrollDifference;
      } else {
        // Scroll to bottom for new messages in an existing conversation
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }

      // Update refs for next render
      scrollPositionRef.current = messageListRef.current.scrollTop;
      prevScrollHeightRef.current = messageListRef.current.scrollHeight;
    }
  }, [messages, selectedMessageId, isFromSearch]);

  const handleLoadOlder = async () => {
    if (messageListRef.current) {
      scrollPositionRef.current = messageListRef.current.scrollTop;
      prevScrollHeightRef.current = messageListRef.current.scrollHeight;
    }
    await loadOlder();
  };

  const handleClickOnSearch = () => {
    navigate(`/conversation/${conversationId}/search`, { replace: true });
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="sticky top-0 z-10 bg-white shadow-md">
        <div className="flex items-center justify-between p-4">
          <button onClick={() => navigate(-1)} className="btn btn-ghost btn-square">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <h1 className="text-xl font-bold">{title}</h1>
          <button onClick={handleClickOnSearch} className="btn btn-ghost btn-square">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto" ref={messageListRef}>
        {hasMoreOlder && (
          <div className="p-2 text-center">
            <button 
              onClick={handleLoadOlder} 
              disabled={isLoadingOlder}
              className="btn btn-primary btn-sm"
            >
              {isLoadingOlder ? 'Loading...' : 'Load Older Messages'}
            </button>
          </div>
        )}
        <div className="p-4 space-y-4">
          {messages.map((message) => (
            <div 
              key={message.id}
              id={`message-${message.id}`}
              className={`chat ${message.sender === userName ? 'chat-end' : 'chat-start'}`}
            >
              <div className={`chat-bubble ${
                message.sender === userName 
                  ? 'chat-bubble-primary' 
                  : 'chat-bubble-secondary'
              } ${message.id === selectedMessageId ? 'ring-2 ring-accent' : ''}`}>
                <p className="font-semibold mb-1 break-words">{message.sender}</p>
                <p className="mb-2 break-words">{message.content}</p>
                <p className="text-xs opacity-75 text-right">
                  {new Date(message.timestamp).toLocaleString()}
                </p>
              </div>
            </div>
          ))}
        </div>
        {hasMoreNewer && (
          <div className="p-2 text-center">
            <button 
              onClick={loadNewer} 
              disabled={isLoadingNewer}
              className="btn btn-primary btn-sm"
            >
              {isLoadingNewer ? 'Loading...' : 'Load Newer Messages'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageList;

