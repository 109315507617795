import { PGlite } from "@electric-sql/pglite";

export const initializeDatabase = async (): Promise<PGlite> => {
  const db = new PGlite("idb://facebook-data", { relaxedDurability: true});

  await db.query(`
    CREATE TABLE IF NOT EXISTS conversations (
      id TEXT PRIMARY KEY,
      title TEXT NOT NULL
    )
  `);

  await db.query(`
    CREATE TABLE IF NOT EXISTS messages (
      id SERIAL PRIMARY KEY,
      conversation_id TEXT,
      sender TEXT,
      timestamp TIMESTAMP WITH TIME ZONE,
      content TEXT,
      message_hash TEXT UNIQUE,
      FOREIGN KEY (conversation_id) REFERENCES conversations(id)
    )
  `);

  await db.query(`
    CREATE TABLE IF NOT EXISTS user_info (
      id INTEGER PRIMARY KEY,
      name TEXT
    )
  `);

  // For full text search
  await db.query(`
    CREATE INDEX IF NOT EXISTS messages_content_fts_idx
    ON messages
    USING gin(to_tsvector('english', content));
  `);

  // For message fetching
  await db.query(`
    CREATE INDEX IF NOT EXISTS messages_conversation_timestamp_id_idx
    ON messages(conversation_id, timestamp, id);
  `);

  return db;
};

