import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePGlite } from '@electric-sql/pglite-react';

interface Conversation {
  id: string;
  title: string;
  lastmessage: string;
  timestamp: string;
}

const ConversationList: React.FC = () => {
  const db = usePGlite();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchConversations = async () => {
      const result = await db.query(`
        SELECT 
          c.id,
          c.title,
          m.content as lastmessage,
          m.timestamp
        FROM conversations c
        LEFT JOIN (
          SELECT DISTINCT ON (conversation_id)
            conversation_id,
            content,
            timestamp
          FROM messages
          ORDER BY conversation_id, timestamp DESC
        ) m ON c.id = m.conversation_id
        ORDER BY m.timestamp DESC NULLS LAST
      `);
      setConversations(result.rows as Conversation[]);
    };

    fetchConversations();
  }, [db]);

  const filteredConversations = conversations.filter(conversation =>
    conversation.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col h-full">
      <div className="p-4">
        <input
          type="text"
          placeholder="Search conversations..."
          className="input input-bordered w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="flex-grow overflow-y-auto">
        <ul className="menu bg-base-100 w-full p-0">
          {filteredConversations.map((conversation) => (
            <li key={conversation.id}>
              <Link to={`/conversation/${conversation.id}`} className="flex flex-col items-start py-3 px-4 hover:bg-base-200 break-words">
                <span className="font-medium break-words">{conversation.title}</span>
                <span className="text-sm text-base-content text-opacity-60 truncate w-full break-words">
                  {conversation.lastmessage || 'No messages'}
                </span>
                <span className="text-xs text-base-content text-opacity-50">
                  {conversation.timestamp ? new Date(conversation.timestamp).toLocaleString() : 'N/A'}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ConversationList;

