import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { usePGlite } from '@electric-sql/pglite-react';
import debounce from 'lodash/debounce';

interface SearchResult {
  id: number;
  content: string;
  timestamp: Date;
  rank: number;
  sender: string;
}

interface SearchState {
  searchTerm: string;
  searchResults: SearchResult[];
  scrollPosition: number;
}

const MessageSearch: React.FC = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const navigate = useNavigate();
  const db = usePGlite();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const restoredState = sessionStorage.getItem(`searchState-${conversationId}`);
    if (restoredState) {
      const { searchTerm, searchResults, scrollPosition } = JSON.parse(restoredState) as SearchState;
      setSearchTerm(searchTerm);
      setSearchResults(searchResults);
      setTimeout(() => {
        if (resultsRef.current) {
          resultsRef.current.scrollTop = scrollPosition;
        }
      }, 0);
    }
    inputRef.current?.focus();
  }, [conversationId]);

  const performSearch = useCallback(async (term: string) => {
    if (!term.trim()) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);

    try {
      const query = `
        SELECT m.id, m.content, m.timestamp, m.sender,
               ts_rank(to_tsvector('english', m.content), plainto_tsquery('english', $1)) AS rank
        FROM messages m
        WHERE m.conversation_id = $2
          AND to_tsvector('english', m.content) @@ plainto_tsquery('english', $1)
        ORDER BY rank DESC
        LIMIT 20;
      `;
      const result = await db.query<SearchResult>(query, [term, conversationId]);
      setSearchResults(result.rows);
    } catch (error) {
      console.error('Error performing full-text search:', error);
    } finally {
      setIsSearching(false);
    }
  }, [db, conversationId]);

  const debouncedSearch = useMemo(
    () => debounce((term: string) => {
      performSearch(term);
    }, 300),
    [performSearch]
  );

  useEffect(() => {
    // Cleanup function to cancel any pending debounced calls when the component unmounts
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleSelectMessage = (messageId: number) => {
    const stateToSave: SearchState = {
      searchTerm,
      searchResults,
      scrollPosition: resultsRef.current?.scrollTop || 0,
    };
    sessionStorage.setItem(`searchState-${conversationId}`, JSON.stringify(stateToSave));
    navigate(`/conversation/${conversationId}`, { 
      state: { selectedMessageId: messageId },
      replace: true
    });
  };

  const handleBack = () => {
    navigate(`/conversation/${conversationId}`, { replace: true });
  };

  const HighlightedText: React.FC<{ text: string; highlight: string }> = ({ text, highlight }) => {
    if (!highlight.trim()) {
      return <>{text}</>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, i) => 
          regex.test(part) ? (
            <mark key={i} className="bg-accent text-accent-content">{part}</mark>
          ) : (
            part
          )
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col h-full bg-base-200">
      <div className="sticky top-0 z-10 bg-base-100 shadow-lg p-4">
        <div className="relative">
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={handleSearchInput}
            placeholder="Search messages..."
            className="input input-bordered w-full pl-10 pr-10"
          />
          <button onClick={handleBack} className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
          </button>
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
          </div>
        </div>
      </div>
      <div ref={resultsRef} className="flex-1 overflow-y-auto p-2">
        {isSearching && (
          <div className="flex justify-center items-center h-full">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        )}
        {searchResults.length > 0 && (
          <div className="space-y-2">
            {searchResults.map((result) => (
              <div
                key={result.id}
                onClick={() => handleSelectMessage(result.id)}
                className="bg-base-100 p-2 rounded-lg shadow hover:shadow-md transition-shadow duration-200 cursor-pointer"
              >
                <div className="flex justify-between items-baseline mb-1">
                  <span className="font-semibold text-sm text-primary">{result.sender}</span>
                  <span className="text-xs text-base-content opacity-60">
                    {new Date(result.timestamp).toLocaleString()}
                  </span>
                </div>
                <p className="text-sm">
                  <HighlightedText text={result.content} highlight={searchTerm} />
                </p>
              </div>
            ))}
          </div>
        )}
        {searchResults.length === 0 && searchTerm && !isSearching && (
          <div className="flex justify-center items-center h-full">
            <div className="alert alert-info">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <span>No results found.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageSearch;

