import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { PGlite } from "@electric-sql/pglite";
import { PGliteProvider } from "@electric-sql/pglite-react";
import FileUpload from './components/FileUpload';
import ConversationList from './components/ConversationList';
import MessageList from './components/MessageList';
import MessageSearch from './components/MessageSearch';
import { initializeDatabase } from './dbSetup';

const LoadingScreen: React.FC = () => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="text-center">
      <div className="mb-4 relative w-16 h-16 mx-auto">
        <div className="absolute inset-0 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <svg className="w-8 h-8 text-blue-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
);

const App: React.FC = () => {
  const [db, setDb] = useState<PGlite | null>(null);
  const [hasConversations, setHasConversations] = useState<boolean | null>(null);

  useEffect(() => {
    const initDb = async () => {
      try {
        const pgliteInstance = await initializeDatabase();
        setDb(pgliteInstance);

        const result = await pgliteInstance.query(`SELECT * FROM messages LIMIT 1`);
        setHasConversations(result.rows.length > 0);
      } catch (error) {
        console.error('Error initializing database:', error);
        setHasConversations(false);
      }
    };

    initDb();
  }, []);

  const handleDataImported = () => {
    setHasConversations(true);
  };

  if (!db || hasConversations === null) {
    return <LoadingScreen />;
  }

  return (
    <PGliteProvider db={db}>
      <Router>
        <div className="min-h-screen bg-base-100">
          <Routes>
            <Route path="/" element={
              !hasConversations ? (
                <div className="hero min-h-screen bg-base-200">
                  <div className="hero-content text-center">
                    <div className="max-w-md">
                      <h1 className="text-5xl font-bold mb-5">Facebook Message Viewer</h1>
                      <FileUpload onDataImported={handleDataImported} />
                    </div>
                  </div>
                </div>
              ) : (
                <ConversationList />
              )
            } />
            <Route path="/conversation/:conversationId" element={<MessageList />} />
            <Route path="/conversation/:conversationId/search" element={<MessageSearch />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
    </PGliteProvider>
  );
};

export default App;

